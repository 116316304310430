<main class="splash-login">
  <h1 class="accessibility-element" uLocalize="res_ProductName"></h1>
  <div class="login-wrapper">
    <section>
      <div class="logo-wrapper">
        <div class="logo" uAriaLabel="res_ProductName" role="img"></div>
      </div>
      <button
        mat-flat-button
        class="mat-signin"
        data-autotest="LoginSSO"
        (click)="ssoSignIn()">
        <span uLocalize="res_SignIn"></span>
      </button>
    </section>
    <mat-error role="alert" class="error-message">{{ root.i18n.localize(errorText) }}</mat-error>
  </div>
</main>
