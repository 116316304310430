import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommonBLService } from 'common-ng/services/common-bl.service';

declare const Circuit: any;
declare const LoginHelper: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonBlService: CommonBLService) {
  }

  private _urlParams: Params | undefined;
  private LogSvc: any;
  private LoginSvc: any;
  private LocalStoreKey: any;

  authResponse!: number;
  logoutReason = '';
  errorText = '';
  helpUrl: string | undefined;
  templateUrl: string | undefined;
  isMobile: boolean | undefined;
  mobileOSType: any;
  regState: any;
  root: any;

  private redirectToUrl(url: string) {
    window.location.href = url;
  }

  ssoSignIn() {
    this.LoginSvc.setItem(this.LocalStoreKey.SSOLOGIN, true);
    let url = '/web/login';
    if (this._urlParams && this._urlParams.return_to) {
      url = url + '?return_to=' + encodeURIComponent(this._urlParams.return_to);
    }
    this.redirectToUrl(url);
  }

  private handleAuthResponse() {
    // Handle success code (0)
    if (this.authResponse === LoginHelper.AUTH_CODES.BACKEND_SUCCESS) {
      return;
    }

    // Handle NaN (converted undefined param)
    if (Number.isNaN(this.authResponse)) {
      this.errorText = 'res_auth_GenericLoginError';
      return;
    }

    // Use authResponse as an array index to retrieve the corresponding message
    this.errorText = LoginHelper.AUTH_RES[this.authResponse!] || 'res_auth_GenericLoginError';
  }

  private handleLogoutReason() {
    let errorCode;
    switch (this.logoutReason) {
    case 'invalidAtcLicense':{
      errorCode = LoginHelper.AUTH_CODES.NO_USER_LICENSE;
      break;
    }
    case 'sessionExpiring':{
      errorCode = LoginHelper.AUTH_CODES.SESSION_EXPIRING;
      break;
    }
    case 'newConnectionDetected':{
      errorCode = LoginHelper.AUTH_CODES.NEW_CONNECTION_DETECTED;
      break;
    }
    case 'accountSuspended':{
      errorCode = LoginHelper.AUTH_CODES.ACCOUNT_DISABLED;
      break;
    }
    }
    if (errorCode) {
      this.errorText = LoginHelper.AUTH_RES[errorCode];
    }
  }

  ngOnInit(): void {
    const serviceInstances = Circuit.serviceInstances;
    this.LoginSvc = serviceInstances.loginSvc;
    this.LogSvc = serviceInstances.logSvc;
    this.LocalStoreKey = this.LoginSvc.LocalStoreKey;
    const Utils = Circuit.Utils;
    this.root = this.commonBlService.getRootScopeData();

    this.route.queryParams.subscribe(params => {
      this._urlParams = params;

      if (this._urlParams.authRes) {
        this.authResponse = parseInt(this._urlParams.authRes, 10);
        this.handleAuthResponse();
      } else if (this._urlParams.reason) {
        this.logoutReason = this._urlParams.reason;
        this.handleLogoutReason();
      }

      this.helpUrl = Utils.DEFAULT_HELP_URL;

      this.templateUrl = 'views/login/loginMain.html';

      this.isMobile = /mobile/i.test(navigator.userAgent);
      this.mobileOSType = this.LoginSvc.getMobileOSType();
      this.regState = this._urlParams.regState;
    });
  }
}
